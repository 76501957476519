(function () {
  'use strict';

  angular
  .module('neo.home.seasons.newSeason')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.seasons.newSeason', {
      url: '/new-temporada',
      templateUrl: 'home/seasons/new-season/new-season.tpl.html',
      controller: 'NewSeasonCtrl',
      controllerAs: 'vm',
      resolve: {
        acl: function ($q, AclService) {
          if (AclService.can('SEASON', 'C')) {
            // Has proper permissions
            return true;
          } else {
            // Does not have permission
            return $q.reject('Unauthorized');
          }
        }
      }

    });
  }
}());
